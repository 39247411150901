@import url("https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Syncopate:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Syncopate:wght@400;700&display=swap");

/* Light mode defaults */
:root {
	--bg-color: #ffffff;
	--text-color: #000000;
}

/* Dark mode variables override */
.dark-mode {
	--bg-color: #0e0e0e;
	--text-color: #ffffff;
}

* {
	font-family: "Oxanium", "Source Code Pro", "Protest Strike", sans-serif;
	box-sizing: border-box;
	background-color: var(--bg-color);
	color: var(--text-color);
}


/* Bubble Items & Navbar */
.bubble-container {
	display: flex;
	justify-content: center;
	gap: 10px;
	padding: 10px 0;
	width: 100%;
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1010;
	background-color: transparent !important;
}

/* Unified bubble-item styling to rely on variables */
.bubble-item {
	background-color: var(--bg-color);
	color: var(--text-color);
	border-style: solid;
	border-color: var(--text-color);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	border-radius: 50px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	text-align: center;
	white-space: nowrap;

	margin: 0;
	width: 90px;
	height: 40px;
	line-height: 1.2;
}

.bubble-item:hover {
	transform: scale(1.05);
	background-color: var(--bg-color);
	color: var(--text-color);
}

.navbar-icon {
	color: var(--text-color);
	background-color: transparent;
	transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out,
		background-color 0.3s ease, color 0.3s ease;
  }

  .icon-bubble {
	background-color: var((--bg-color));
	width: 25px;
}

.icon-bubble:hover {
	transform: scale(1.05);
	background-color: var(--bg-color);
	color: var(--text-color);
}

/* Fade section */
.fade-section {
	opacity: 0;
	transform: translateY(20vh);
	visibility: hidden;
	transition: opacity 0.6s ease-out, transform 1.2s ease-out;
	will-change: opacity, visibility;
}

.fade-section.is-visible {
	opacity: 1;
	transform: none;
	visibility: visible;
}

/* Navbar */
.navbar-transparent {
	background-color: transparent;
	border: none;
	box-shadow: none;
	padding: 0;
}

/* Home component */
.home-container {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 90vh;
	padding: 0 2rem;
	box-sizing: border-box;
}

.hero-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 1rem;
}

@media (min-width: 992px) {
	.hero-section {
		padding-top: 30px;
		flex-direction: row;
		justify-content: space-between;
		max-width: 900px;
		width: 100%;
		gap: 2rem;
	}
}

.text-container {
	max-width: 600px;
}

.intro-text {
	font-size: 1.5rem;
	font-weight: 300;
	margin: 0;
	color: var(--text-color);
}

.name-title {
	font-size: 4rem;
	font-weight: 300;
	margin: 0.5rem 0 0.5rem 0;
	color: var(--text-color);
	line-height: 1.1;
}

.subtitle {
	font-size: 1.2rem;
	color: var(--text-color);
	margin-bottom: 1rem;
}

.image-container img,
.profile-image {
	width: 400px;
	height: 500px;
	object-fit: cover;
	border-radius: 20%;
	filter: grayscale(60%);
	transition: filter 0.3s ease, transform 0.3s ease;
}

.profile-image:hover {
	filter: grayscale(0%);
	transform: scale(1.05);
}

.profile-image.hovered {
	filter: grayscale(0%);
	transform: scale(1.05);
}

@media (max-width: 991px) {
	.text-container,
	.image-container {
		margin: 0;
	}
}

/* Type */
.typewriter-text span {
	font-size: 24px;
	color: var(--text-color);
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

.highlight-cursor:hover {
	transform: scale(1.1);
	animation: pulse 0.5s infinite;
}

/* Personal Info Page */
.btn-container {
	margin-top: 7%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pi-btn {
	user-select: none;
	margin-left: 5px;
	margin-right: 5px;
	border: solid 1px var(--text-color);
	border-radius: 8px;
	padding: 8px;
	text-align: center;
	cursor: pointer;
	background-color: var(--bg-color);
	color: var(--text-color);
	transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.pi-btn:hover {
	background-color: var(--text-color);
	color: var(--bg-color);
	transform: scale(1.1);
}

.pi-btn.active {
	background-color: var(--text-color);
	color: var(--bg-color);
}

.pi-title {
	font-size: 40px;
	margin-top: 2%;
	margin-bottom: 10px;
	color: var(--text-color);
	text-align: center;
}

/* About Section */
.about {
	max-width: 1000px;
	margin: 0 auto;
	padding: 20px;
	text-align: center;
}

.about-title {
	font-size: 30px;
	margin-bottom: 10px;
	color: var(--text-color);
}

.about-body {
	font-size: 24px;
	line-height: 1.5;
}

.bold-word {
	font-size: larger;
	font-style: italic;
	font-weight: bold;
	color: var(--text-color);
}

/* Work Experience */
.workExperience {
	max-width: 800px;
	margin: 0 auto;
	padding: 20px;
}

.workList {
	margin: 0 auto;
	margin-top: 3%;
	text-align: left;
	max-width: 600px;
	padding-left: 20px;
}

.workList h4,
.workList p {
	margin: 10px 0;
}

.companyName {
	color: var(--text-color);
}

.swe-role {
	font-style: italic;
}

/* Education */
.educationList {
	margin: 0 auto;
	margin-top: 3%;
	text-align: left;
	max-width: 600px;
	padding-left: 20px;
}

.educationList li {
	list-style: none;
}

.degree {
	font-style: italic;
}

.schoolname {
	color: var(--text-color);
}

/* Skills */
.skills {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}

.skill-list {
	margin: 0 auto;
	margin-top: 3%;
	text-align: left;
	max-width: 600px;
	padding-left: 20px;
}

.skill-header {
	color: var(--text-color);
	font-size: 24px;
}

/* Projects */
.projects {
	margin-top: 10%;
	padding: 0 10px;
}

.project-headtitle {
	margin-bottom: 2%;
	color: var(--text-color);
	text-align: center;
	font-size: 1.8rem;
}

.project-title {
	color: var(--text-color);
	font-style: italic;
	text-decoration: underline;
	font-size: 1.2rem;
}

.project-body {
	color: var(--text-color);
	font-size: 1rem;
}

.card-link {
	text-decoration: none;
}

.card-img-container {
	height: 200px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-img-top {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.card {
	border: 2px solid var(--text-color);
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
	margin: 10px;
}

.card:hover {
	transform: scale(1.05); 
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); 
}

.slick-prev,
.slick-next {
	color: #61a5c2;
	z-index: 1;
}

@media (max-width: 768px) {
	.projects {
		margin-top: 5%;
	}

	.card-img-container {
		height: 150px;
	}

	.project-headtitle {
		font-size: 1.5rem;
	}

	.project-title {
		font-size: 1rem;
	}

	.project-body {
		font-size: 0.9rem;
	}
}

/* Contact */
.contact {
	margin-top: 15%;
	margin-bottom: 2.5%;
}

.contact-header {
	align-items: center;
	justify-content: center;
	margin-bottom: 2%;
}

.contact-header h2 {
	font-size: 70px;
}

.contact-header p {
	color: var(--text-color);
	font-size: 30px;
}

.email p {
	font-size: 60px;
	color: var(--text-color);
}

.social-header {
	text-decoration: underline;
}

.bi {
	font-size: 60px;
}

.list-inline-item.social {
	margin-right: 10px;
}

.list-inline-item.social a {
	text-decoration: none;
	color: inherit;
}

.list-inline-item.social a:hover {
	text-decoration: underline;
}

@media (max-width: 768px) {
	.contact {
		margin-top: 20%;
		margin-bottom: 15%;
	}

	.contact-header h2 {
		font-size: 40px;
	}

	.contact-header p {
		font-size: 20px;
	}

	.email p {
		font-size: 30px;
	}

	.bi {
		font-size: 40px;
	}

	.list-inline-item.social {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px; 
	}

	.list-inline-item.social a {
		font-size: 20px;
	}
}

/* Footer */
.footer {
	text-align: center;
	color: var(--text-color);
}

.footer .text-muted {
	color: var(--text-color) !important;
}

.footer-container {
	columns: var(--text-color);
}

.backToTop {
	display: inline-block;
	background-color: var(--bg-color);
	color: var(--text-color);
	padding: 15px 30px;
	border-radius: 50px;
	border-style: solid;
	border-color: var(--text-color);;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	transition: transform 0.2s ease-in-out, opacity 0.3s ease-in-out;
	text-align: center;
	white-space: nowrap;
	margin-bottom: 5%;
	font-size: 1.2em;
	line-height: 1.2;
	text-decoration: none;
}

.backToTop:hover {
	transform: scale(1.05);
}

@media screen and (max-width: 1024px) {
	.bubble-container {
		display: flex;
		justify-content: center;
		gap: 10px;
		padding: 10px 0;
		width: 70%;
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1010;
		background-color: transparent !important;
	}

	.home-container {
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
		padding: 0 2rem;
		box-sizing: border-box;
		margin-top: 100px;
	}

	.about-body {
		font-size: 20px;
	}

	.image-container img,
	.profile-image {
		padding-top: 25px;
		width: 350px;
	}

	
}



